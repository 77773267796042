import { HomePage } from "./pages/Home";
import { HomeNew } from "./pages/HomeNew";
import { SearchPage, CategoryPage } from "./pages/Search";
import { ConvertingMethodPage } from "./pages/ConvertingMethod";
import { MaterialPage } from "./pages/Material";
import { DimensionsPage } from "./pages/Dimensions";
import { FeaturesPage } from "./pages/Features";
import { Reset } from "./pages/Reset";
import { AccountLogin } from "./pages/AccountLogin";
import { SignIn } from "./components/SignIn";
import { Register } from "./pages/registration/Register";
import { AccountAccessRequestApprovalPage } from "./pages/AccountAccessRequestApproval";
import { SignedOut } from "./pages/SignedOut";
import { ProductSpecs } from "./pages/offer/ProductSpecs";
import { EditSkusAddress } from "./pages/offer/EditSkusAddress";
import { ReviewOrder } from "./pages/offer/ReviewOrder";
import { PaymentPage } from "./pages/offer/PaymentPage";
import { ArtworkUpload } from "./pages/offer/ArtworkUpload";
import { OrderSummary } from "./pages/offer/OrderSummary";
import { CheckoutLandingStep } from "./pages/offer/ArtworkPayment";
import { OfferNotFound } from "./pages/offer/OfferNotFound";
import { OrderedProducts } from "./pages/reorder/OrderedProducts";
import { ReorderSkuSelection } from "./pages/reorder/ReorderSkuSelection";
import { ReorderSkuSelectionUnauthenticated } from "./pages/reorder/ReorderSkuSelectionUnauthenticated";
import { ReorderQuantitySelection } from "./pages/reorder/ReorderQuantitySelection";
import { ReorderQuantitySelectionUnauthenticated } from "./pages/reorder/ReorderQuantitySelectionUnauthenticated";
import { OrderCompleteLayout } from "./pages/offer/OrderCompleteLayout";
import { OrderPlacementRouter } from "./infrastructure/OrderPlacementRouter";
import { Navigate } from "react-router-dom";
import { SkusPageProtected } from "./pages/SkusProtected";
import { PouchPreviewPage } from "./components/offer/PouchPreviewPage";
import { RegisterAccount } from "./pages/account/RegisterAccount";
import { MyOrders } from "./pages/account/MyOrders";
import { MyAddresses } from "./pages/account/MyAddresses";
import { AddShippingAddress } from "./pages/account/AddShippingAddress";
import { EditShippingAddress } from "./pages/account/EditShippingAddress";
import { Login } from "./pages/account/Login";
import { EditBillingAddress } from "./pages/account/EditBillingAddress";
import { ArtworkReview } from "./pages/reorder/ArtworkReview";
import { CertificateDetailsPage } from "./pages/offer/CertificateDetailsPage";
import { MyAccountLandingPage } from "./pages/account/MyAccountLandingPage";
import { RegistrationPage } from "./pages/registration/RegistrationPage";

const AppRoutes = [

    {
        index: true,
        element: <HomeNew />
    },

    // ------------ shop ---------------------
    {
        path: '/:region',
        element: <CategoryPage />
    },
    {
        path: '/:region/search',
        element: <CategoryPage />
    },
    {
        path: '/:region/noresults',
        element: <CategoryPage noSearchResults={true} />
    },
    {
        path: '/:region/categories/:cat',
        element: <CategoryPage />
    },
    {
        path: '/:region/search/:search',
        element: <SearchPage />
    },
    {
        path: '/:region/group/:group/convertingMethod',
        element: <ConvertingMethodPage />
    },
    {
        path: '/:region/group/:group/convertingMethod/:method/dimensions',
        element: <DimensionsPage />
    },
    {
        path: '/:region/group/:group/convertingMethod/:method/dimensions/material',
        element: <MaterialPage />
    },
    {
        path: '/:region/group/:group/convertingMethod/:method/material/:material/features',
        element: <FeaturesPage />
    },
    {
        path: '/:region/group/:group/convertingMethod/:method/material/:material/skus',
        element: <SkusPageProtected />
    },
    {
        path: '/:region/group/:group/convertingMethod/:method/material/:material/login',
        element: <AccountLogin />
    },

    // ---------------  account management   -----------------------
    {
        path: '/login',
        element: <Login />
    },
    {
        path: '/sign-in',
        element: <SignIn />
    },

    {
        path: '/registration',
        element: <RegistrationPage />
    },
    {
        path: '/myEpac/:region',
        element: <MyAccountLandingPage />
    },
    {
        path: '/signout',
        element: <SignedOut />
    },
    {
        path: '/reset',
        element: <Reset />
    },
    {
        path: '/myaddresses',
        element: <MyAddresses />
    },
    {
        path: '/myaddresses/add-shipping-address',
        element: <AddShippingAddress />
    },
    {
        path: '/myaddresses/edit-shipping-address',
        element: <EditShippingAddress />
    },
    {
        path: '/myaddresses/billing/edit',
        element: <EditBillingAddress />
    },
    {
        path: '/register/account',
        element: <RegisterAccount />
    },
    {
        path: '/myorders',
        element: <MyOrders />
    },
    {
        path: '/request/approval/:key',
        element: <AccountAccessRequestApprovalPage />   // deprecated ?
    },
    {
        path: '/register',
        element: <Register />   // deprecated ?
    },

    // -------------   order placement  --------------
    {
        path: '/offer/*',
        element: <OfferNotFound />
    },
    {
        path: '/offer/:globalOfferId',
        element: <OrderPlacementRouter evaluate={(routeData) => {
            if (routeData.hasSubmittedCheckout) {
                return <Navigate to={`/offer/${routeData.globalOfferId}/todo`} state={{ redirected: true }} replace />;
            }
            return <Navigate to={`/offer/${routeData.globalOfferId}/product`} state={{ redirected: true }} replace />;
        }} />,
        index: true
    },
    {
        path: '/offer/:globalOfferId/product',
        element: <OrderPlacementRouter evaluate={(routeData) => {
            if (routeData.hasSubmittedCheckout) {
                return <Navigate to={`/offer/${routeData.globalOfferId}/todo`} state={{ redirected: true }} replace />;
            }
            return <ProductSpecs
                analyticsMetadata={routeData.analyticsMetadata}
                offer={routeData.offer}
                salesforceAccount={routeData.salesforceAccount}
                formattedSizeString={routeData.formattedSizeString}
                status={routeData.status}
                orderReference={routeData.orderReference}
                isOrderPlacementEnabled={routeData.isOrderPlacementEnabled}
                globalOffer={routeData.globalOffer} />;
        }} />
    },
    {
        path: '/offer/:globalOfferId/skus',
        element: <OrderPlacementRouter evaluate={(routeData) => {
            if (routeData.hasSubmittedCheckout) {
                return <Navigate to={`/offer/${routeData.globalOfferId}/todo`} state={{ redirected: true }} replace />;
            }
            return <EditSkusAddress
                analyticsMetadata={routeData.analyticsMetadata}
                offer={routeData.offer}
                salesforceAccount={routeData.salesforceAccount}
                status={routeData.status}
                orderReference={routeData.orderReference}
                orderConfiguration={routeData.orderConfiguration}
                globalOffer={routeData.globalOffer}
            />;
        }} />
    },
    {
        path: '/offer/:globalOfferId/review',
        element: <OrderPlacementRouter evaluate={(routeData) => {
            if (routeData.hasSubmittedCheckout) {
                return <Navigate to={`/offer/${routeData.globalOfferId}/todo`} state={{ redirected: true }} replace />;
            }

            return <ReviewOrder
                analyticsMetadata={routeData.analyticsMetadata}
                globalOfferId={routeData.globalOfferId}
                orderReference={routeData.orderReference}
                offer={routeData.offer}
                termsConditionUrl={routeData.termsConditionUrl}
                orderConfiguration={routeData.orderConfiguration} />;
        }} />
    },
    {
        path: '/offer/:globalOfferId/pay',
        element: <OrderPlacementRouter evaluate={(routeData) => {
            if (!routeData.hasSubmittedCheckout) {
                return <Navigate to={`/offer/${routeData.globalOfferId}/product`} state={{ redirected: true }} replace />;
            }
            return <PaymentPage
                analyticsMetadata={routeData.analyticsMetadata}
                offer={routeData.offer}
                orderReference={routeData.orderReference}
                orderIntent={routeData.orderIntent}
                status={routeData.status}
                initialized={routeData.initialized} />;

        }} />
    },
    {
        path: '/offer/:globalOfferId/artwork',
        element: <OrderPlacementRouter evaluate={(routeData) => {
            if (!routeData.hasSubmittedCheckout) {
                return <Navigate to={`/offer/${routeData.globalOfferId}/product`} state={{ redirected: true }} replace />;
            }
            if (routeData.offer.reorderDetails) {
                return <ArtworkReview
                    offer={routeData.offer}
                    orderIntent={routeData.orderIntent}
                    orderConfiguration={routeData.orderConfiguration}
                />;
            }

            return <ArtworkUpload
                analyticsMetadata={routeData.analyticsMetadata}
                offer={routeData.offer}
                orderReference={routeData.orderReference}
                orderIntent={routeData.orderIntent}
                status={routeData.status}
                orderConfiguration={routeData.orderConfiguration}
            />;

        }} />
    },
    {
        path: '/offer/:globalOfferId/artworkfrominvite',
        element: <OrderPlacementRouter evaluate={(routeData) => {
            if (!routeData.hasSubmittedCheckout) {
                return <Navigate to={`/offer/${routeData.globalOfferId}/product`} state={{ redirected: true }} replace />;
            }
            return <ArtworkUpload
                analyticsMetadata={routeData.analyticsMetadata}
                offer={routeData.offer}
                orderReference={routeData.orderReference}
                orderIntent={routeData.orderIntent}
                status={routeData.status} />;
        }} />
    },
    {
        path: '/offer/:globalOfferId/payfrominvite',
        element: <OrderPlacementRouter evaluate={(routeData) => {
            if (!routeData.hasSubmittedCheckout) {
                return <Navigate to={`/offer/${routeData.globalOfferId}/product`} state={{ redirected: true }} replace />;
            }
            return <PaymentPage
                analyticsMetadata={routeData.analyticsMetadata}
                offer={routeData.offer}
                orderReference={routeData.orderReference}
                orderIntent={routeData.orderIntent}
                status={routeData.status}
                initialized={routeData.initialized} />;
        }} />
    },
    {
        path: '/offer/:globalOfferId/summary',
        element: <OrderPlacementRouter evaluate={(routeData) => {
            if (!routeData.hasSubmittedCheckout) {
                return <Navigate to={`/offer/${routeData.globalOfferId}/product`} state={{ redirected: true }} replace />;
            }
            if (routeData.hasSubmittedCheckout && !routeData.hasSubmittedOrder) {
                return <Navigate to={`/offer/${routeData.globalOfferId}/todo`} state={{ redirected: true }} replace />;
            }
            return <OrderSummary
                backwardsCompatibilityOffer={routeData.backwardsCompatibilityOffer}
                globalOfferId={routeData.globalOfferId}
                status={routeData.status}
            />;

        }} />
    },
    {
        path: '/offer/:globalOfferId/complete',
        element: <OrderPlacementRouter evaluate={(routeData) => {
            /*if (!routeData.hasSubmittedCheckout || routeData.hasSubmittedOrder) {
                return <Navigate to={`/offer/${routeData.globalOfferId}`} state={{ redirected: true }} replace />;
            }*/
            return <OrderCompleteLayout
                offer={routeData.offer}
                status={routeData.status}
                initialized={routeData.initialized}
                orderReference={routeData.orderReference}
                globalOfferId={routeData.globalOfferId}
                orderConfiguration={routeData.orderConfiguration}
            />
        }} />
    },
    {
        path: '/offer/:globalOfferId/todo',
        element: <OrderPlacementRouter evaluate={(routeData) => {
            if (routeData.hasSubmittedCheckout && routeData.hasSubmittedOrder) {
                return <Navigate to={`/offer/${routeData.globalOfferId}/complete`} state={{ redirected: true }} replace />;
            }
            else if (!routeData.hasSubmittedCheckout) {
                return <Navigate to={`/offer/${routeData.globalOfferId}/product`} state={{ redirected: true }} replace />;
            }
            return <CheckoutLandingStep
                analyticsMetadata={routeData.analyticsMetadata}
                offer={routeData.offer}
                orderReference={routeData.orderReference}
                orderIntent={routeData.orderIntent}
                orderConfiguration={routeData.orderConfiguration}
                status={routeData.status}
                globalOfferId={routeData.globalOfferId} />;
        }} />
    },
    {
        //the path is how to reach the component
        path: '/test/pouchpage/:globalOfferId',
        //the element is what gets returned
        element: <PouchPreviewPage />
        //PouchPreviewPage is imported above (the .jsx file)
    }, 
    {
        path: '/offer/:globalOfferId/certificates',
        element: <CertificateDetailsPage />
    },

    // ------   reorder -----------
    {
        path: '/myorders/:globalOrderId/reorder',
        element: <ReorderSkuSelection />
    },
    {
        path: '/myorders/:globalOrderId/reorder/quantity',
        element: <ReorderQuantitySelection />
    },
    {
        path: '/myreorders',
        element: <OrderedProducts /> // deprecated ?
    },
    {
        path: '/reorder/:reorderIntent',
        element: <ReorderSkuSelectionUnauthenticated /> // deprecated ?
    },
    {
        path: '/reorder/:reorderIntent/quantity',
        element: <ReorderQuantitySelectionUnauthenticated />    // deprecated ?
    },
    {
        path: '/myreorders/reorder',
        element: <ReorderQuantitySelection />       // deprecated ?
    }
];

export default AppRoutes;
