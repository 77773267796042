import { useEffect, useContext, useState } from "react";
import { React } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from 'react-router-dom';
import { useMsal, useAccount, AuthenticatedTemplate } from "@azure/msal-react";
import { loginRequest, protectedResources } from "../../authConfig";
import { ApiDataAccess } from "../../infrastructure/ApiDataAccess";
import { CapabilityName } from '../../infrastructure/Constants';
import { CapabilitiesContext } from '../../infrastructure/Contexts';
import { Language } from '../../infrastructure/Languages';
import { RequiredRegistrationTextbox } from "../../components/registration/RequiredRegistrationTextbox";
import { RegistrationSelectionDropdown } from '../../components/registration/RegistrationSelectionDropdown'


// new registration page when user try to register from epac website
export const RegistrationPage = ({ onError, submitError }) => {

    const language = useContext(Language);
    const capabilitiesContext = useContext(CapabilitiesContext);
    const loader = capabilitiesContext.getCapability(CapabilityName.Loader);
    const navigate = useNavigate();
    const location = useLocation();
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    const culture = location.state.culture;
    const userDataAccess = new ApiDataAccess("/api/user");
    const [user, setUser] = useState();

    const validationTypes = ["Account #", "Quote/Order #"];

    const {
        handleSubmit,
        control,
        setValue,
        formState: { errors },
    } = useForm({
        mode: "onChange",
        defaultValues: {
            validationType: null,
            validationString: ""
        },
    });

    useEffect(() => {
        const init = async () => {
            var user = await getUser();
            setUser(user);
        }
        init();
    }, []);

    const verifyUserIsLinkedToSalesforceAccount = async () => {
        var user = await getUser();
        setUser(user);
        return !!user.salesforceAccountId;
    }

    const getUser = async () => {
        const response = await instance.acquireTokenSilent({
            scopes: [...protectedResources.shopApp.scopes],
            ...loginRequest,
            account: account,
        });

        if (!response.accessToken) {
            throw new Error("Could not retrieve access token.");
        }

        var user = await userDataAccess.get(
            `/search?emailAddress=${encodeURIComponent(
                account.username
            )}&identityProviderAccountId=${encodeURIComponent(account.idTokenClaims.oid)}`,
            response.accessToken,
            {
                throwOnNonSuccess: false
            }
        );

        if (user.hasSuccess) {
            console.log("A user was found", user);
            return user;

        } else {
            console.log("Didn't find a user.", user.statusCode);
            return null;
        }
    }

    const onFormError = (errors, e) => {
        debugger
    };

    const onValidationTypeChanged = (e) => {
        setValue("validationType", e.target.selectedIndex);
    }

    const onSubmitForm = (data, e) => {
        var request = {
            culture: culture,
            emailAddress: user.emailAddress,
            validationType: data.validationType,
            validationString: data.validationString
        };
        userDataAccess.put('/account/salesforce', request).then(response => {
            verifyUserIsLinkedToSalesforceAccount().then(response => {
                if (response)
                    navigate('myorders');
            });
        });
    };

    return (
        <div>
            <AuthenticatedTemplate>
                <h3>User Account Registration</h3>
                <form className="registration-container" onSubmit={handleSubmit(onSubmitForm, onFormError)}>
                    <div className="registration-grid-item">
                        <RegistrationSelectionDropdown
                            data={validationTypes}
                            control={control}
                            label={"Account validation type" /*language.translateLabel('', '', 'Label')*/}
                            rules={{ required: "a value is required" /*language.translateLabel('6a5500e5-d62a-40e0-9efa-0a10e5377643', 'A first name is required', 'Label')*/ }}
                            name="validationType"
                            onValueChanged={onValidationTypeChanged}
                        />
                    </div>
                    <div className="registration-grid-item">
                        <RequiredRegistrationTextbox
                            control={control}
                            placeHolder={"value" /*language.translateLabel('ce014de7-4ecf-4960-b753-d9e253234b3c', '', 'Label')*/}
                            label={"value" /*language.translateLabel('ce014de7-4ecf-4960-b753-d9e253234b3c', '', 'Label')*/}
                            rules={{ required: "a value is required" /*language.translateLabel('6a5500e5-d62a-40e0-9efa-0a10e5377643', 'A first name is required', 'Label')*/ }}
                            name="validationString"
                        />
                    </div>
                    {submitError && submitError.length > 0 && (
                        <div className="registration-notifications">
                            <ul className="registration-validation-error">
                                {submitError.map((item, idx) => <li key={idx}>{item}</li>)}
                            </ul>
                        </div>
                    )}
                    <div className="registration-submit">
                        <button type="submit" className="btn btn-primary">
                            Register
                        </button>
                    </div>
                </form>
            </AuthenticatedTemplate>
        </div>
    )
};
