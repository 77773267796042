import React from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useMsal, useAccount, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { loginRequest, protectedResources } from "../../authConfig";
import { Login } from "./Login";
import { SecureApiDataAccess } from "../../infrastructure/SecureApiDataAccess";
import { CapabilitiesContext } from "../../infrastructure/Contexts";
import { CapabilityName } from "../../infrastructure/Constants";

export const MyAccountLandingPage = () => {

    let { region } = useParams();
    //const capabilitiesContext = useContext(CapabilitiesContext);
    //const loader = capabilitiesContext.getCapability(CapabilityName.Loader);
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const userDataAccess = new SecureApiDataAccess("/api/user");
    const navigate = useNavigate();

    const route = async () => {
        var user = await getUser();
        if (user == null)
            console.log("do something");

        if (!user.salesforceAccountId)
            navigate(`registration`, {
                state: {
                    culture: region
                }
            })
        else
            navigate('myorders')
    }

    const getUser = async () => {
        const response = await instance.acquireTokenSilent({
            scopes: [...protectedResources.shopApp.scopes],
            ...loginRequest,
            account: account,
        });

        if (!response.accessToken) {
            throw new Error("Could not retrieve access token.");
        }

        var user = await userDataAccess.get(
            `/search?emailAddress=${encodeURIComponent(
                account.username
            )}&identityProviderAccountId=${encodeURIComponent(account.idTokenClaims.oid)}`,
            response.accessToken,
            {
                throwOnNonSuccess: false
            }
        );

        if (user.hasSuccess) {
            console.log("A user was found", user);
            return user;

        } else {
            console.log("Didn't find a user.", user.statusCode);
            return null;
        }
    }

    return (
        <div>
            <AuthenticatedTemplate>
                { () => { route() } }
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <Login />
            </UnauthenticatedTemplate>
        </div >
    )
}